$table-cell-padding: .5rem;
$input-border-color: #adb5bd;

$breadcrumb-bg: #f6f7f8;

$navbar-dark-color: rgba(#fff, .75) !default;
$navbar-dark-hover-color: #fff !default;
$navbar-active-color: #fff;

@import "../../node_modules/@coreui/coreui/scss/_variables.scss";
